<template>
  <div>
    <b-card>
      <b-row class="mb-1">
        <b-col>
          <h2 class="mt-25">
            {{ capitalizeFirstLetter(currentMonth.format('MMMM YYYY')) }}
          </h2>
        </b-col>
        <b-col class="text-right">
          <b-btn-group>
            <b-button
              :disabled="currentMonth.diff(dayjs(), 'month', true) <= 1"
              variant="outline-secondary"
              @click="prevMonth"
            >
              <feather-icon icon="ChevronLeftIcon" />
            </b-button>
            <b-button
              variant="outline-secondary"
              @click="nextMonth"
            >
              <feather-icon icon="ChevronRightIcon" />
            </b-button>
          </b-btn-group>
        </b-col>
      </b-row>

      <b-row v-if="selectedDates.length === 0">
        <b-col class="text-center">
          <label class="d-block">
            {{ $t('schedule.my-availability.select-days-info') }}
          </label>
        </b-col>
      </b-row>

      <b-row v-else>
        <b-col class="text-center">
          <label class="d-block">
            {{ $t('schedule.my-availability.add-availability-info') }}
          </label>
          <b-btn-group class="flex-wrap">
            <b-button
              v-for="(shiftDefinition, shiftDefinitionKey) in scheduleConfig.shiftDefinitions"
              :key="shiftDefinitionKey"
              variant="flat-primary"
              @click="addAvailability(shiftDefinition)"
            >
              {{ shiftDefinition.name }}
            </b-button>
          </b-btn-group>
        </b-col>
      </b-row>

      <table class="schedule-calendar-table w-100">
        <thead>
          <tr>
            <th>
              <div class="m-50">
                <b-button
                  class="btn-block"
                  :variant="isDark ? 'dark' : 'light'"
                  @click="selectedDates = []"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </div>
            </th>
            <th
              v-for="(day, weekdayKey) in dayjsWeekdays"
              :key="`weekday-key-${weekdayKey}`"
              class="d-none d-lg-table-cell"
            >
              <div class="m-50">
                <b-button
                  :variant="isDark ? 'dark' : 'light'"
                  class="btn-block text-uppercase"
                  @click="toggleDay(weekdayKey)"
                >
                  {{ day }}
                </b-button>
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(week, weekKey) in monthArray"
            :key="`week-key-${weekKey}`"
            class="d-block d-lg-table-row"
          >
            <td
              class="text-uppercase d-block d-md-table-cell align-text-top"
            >
              <div class="m-50">
                <b-button
                  :variant="isDark ? 'dark' : 'light'"
                  class="btn-block"
                  @click="toggleWeek(week.number)"
                >
                  <span class="d-lg-none">
                    {{ $t('schedule.my-availability.week') }}
                  </span>
                  {{ week.number }}
                </b-button>
              </div>
            </td>
            <td
              v-for="(day, dayKey) in week.days"
              :key="`day-key-${dayKey}-${refreshKey}`"
              :style="{ 'width': !isMobileWidth ? '13%' : 'inherit' }"
              class="d-block d-lg-table-cell align-text-top"
            >
              <div
                v-show="day.isCurrentMonth"
                class="m-50"
              >
                <b-button
                  class="btn-block"
                  :variant="isSelected(day.formatted) ? 'primary' : 'outline-secondary'"
                  @click="toggle(day.formatted)"
                >
                  <span class="d-lg-none">
                    {{ capitalizeFirstLetter(day.date.format('dddd')) }}
                  </span>
                  {{ day.date.format('D') }}
                </b-button>

                <b-button
                  v-for="availability in availabilities[day.formatted]"
                  :key="`availability-${availability.shiftDefinitionIdentifier}-${day.formatted}`"
                  size="sm"
                  pill
                  :title="availability.name"
                  variant="secondary"
                  class="my-25 d-block text-nowrap btn-block"
                  @click="removeAvailability(day.formatted, availability.shiftDefinitionIdentifier)"
                >
                  {{ availability.name }}
                  <feather-icon icon="XIcon" /></b-button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <b-row>
        <b-col class="text-right">
          <b-button
            v-if="Object.keys(availabilities).length > 0"
            variant="flat-danger"
            @click="clearCurrentMonth(false)"
          >
            <feather-icon icon="TrashIcon" />
          </b-button>
        </b-col>
      </b-row>
    </b-card>

    {{ availabilities }}
  </div>
</template>

<script>
import setup from './setup'

export default {
  components: {
  },
  setup,
}
</script>

<style lang="scss">
</style>
